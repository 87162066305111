import { fetchUsers } from "../../services/users"

export const isAuthenticated = (history, page) => {
  fetchUsers().then(data => {
    const storage = JSON.parse(localStorage.getItem('@nts-site/authenticated'))
    if(storage){
      const userFound = data.find( ({user}) => ((user.login === storage.login) && (user.id === storage.id) && (user.admin === storage.admin))) 
      if(userFound) {
        history.push('/users')
        return
      }
    }
   if(!(page === 'login'))
    history.push('/')
  })
}