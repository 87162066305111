import styled from "styled-components"

export const TitleModal = styled.h1`
  font-size: 1.5rem;
  font-weight: normal;
  text-align: center;
  font-family: Inter, sans-serif;
  margin-bottom: 10px;
`

export const InputModal = styled.input`
  border: 1px solid #000;
  border-radius: 0.25rem;
  margin-bottom: 10px;
  padding: 5px;
  height: 35px;
  background-color: '#FAFAFA';
`

export const Button = styled.button(props => ({
  backgroundColor: props.background || "#133BEC",
  border: "none",
  borderRadius: "0.25rem",
  color: "#FFF",
  width: props.width,
  fontWeight: "600",
  padding: "10px",
  cursor: "pointer"  
}))

export const ContainerButton = styled.div( props => ({
  display: "flex",
  placeContent: "center",
  padding: "20px 50px",
  justifyContent: props.space || "space-between"
}))

export const Error = styled.span`
  display: block;
  color: #c53030;
  margin-bottom: 5px;
`;

