import moment from 'moment'
import { pipe, map, zipWith, toString, keys, values, slice, mathMod } from 'ramda'

import {
  degToCompass,
  slicer,
  toFixed,
  toPeriod,
  toInitial,
  toDescription,
  groupByDayAndMonth
} from './utils'

export const normalizeMonthlyRecords = ({ meta, points }) => ({
  updated_at: meta.updated_at,
  max_temperature: points.observed.monthly_max_temperature[0].toFixed(1),
  min_temperature: points.observed.monthly_min_temperature[0].toFixed(1),
  thermal_amplitude: points.observed.monthly_thermal_amplitude[0].toFixed(1),
  accumulated_precipitation: points.observed.monthly_accumulated_precipitation[0].toFixed(
    1
  )
})

export const normalizePeriodly = ({ periods, points }) => {
  const { forecast } = points
  const dates = periods.slice(0, 12)

  const {
    precipitation,
    pressure,
    rel_humidity,
    wind_speed,
    wind_direction,
    temperature,
    wheather_conditions
  } = forecast

  const normalized = normalize({
    precipitation,
    pressure,
    humidity: rel_humidity,
    windSpeed: wind_speed,
    windDirection: wind_direction,
    temperature,
    weatherCondition: wheather_conditions,
    periods
  })

  const structured = dates.map((date, index) => ({
    date: moment(date),
    rain: normalized.precipitation[index],
    pressure: normalized.pressure[index],
    period: toPeriod(moment(date).format('HH')),
    condition: {
      initial: toInitial(
        normalized.weatherCondition[index],
        moment(date).format('HH')
      ),
      description: toDescription(normalized.weatherCondition[index])
    },
    wind: {
      direction: normalized.windDirection[index],
      speed: normalized.windSpeed[index]
    },
    windSpeed: normalized.windSpeed[index],
    humidity: normalized.humidity[index],
    temperature: normalized.temperature[index]
  }))

  try {
    return {
      chart: dates.map((date, index) => ({
        date,
        temperature: normalized.temperature[index],
        rain: normalized.precipitation[index]
      })),
      table: zipWith(
        (key, value) => Object.assign({}, { date: key, dates: value }),
        keys(groupByDayAndMonth(structured)),
        values(groupByDayAndMonth(structured))
      )
    }
  } catch (error) {
    throw new Error(error)
  }
}

export const normalizeSegmentDaily = ({ data }) => {
  const variables = data.variables;
  const dates = slicer(data.periods);

  const table = dates.map((value, index) => {
    const date = moment(value);

    return {
      date: date.format('YYYY-MM-DD'),
      dates: [
        {
          date,
          rain: Math.round(variables.precipitation[index]),
          windSpeed: Math.round(variables.wind_speed[index]),
          humidity: variables.rh[index],
          temperature: Math.round(variables.temperature[index]),
          stormIndex: variables.storm_index[index],
          lightning: variables.lightning[index],
          landslideIndex: variables.landslide_index[index]
        }
      ]
    }
  });

  const chart = dates.map((date, index) => {
    return {
      date,
      temperature: Math.round(variables.temperature[index]),
      rain: Math.round(variables.precipitation[index])
    }
  });

  return {
    table,
    chart
  }
}

export const normalizeSegmentPeriodly = (segment) => {

  const segmentThreeDays = segment.slice(0, 12)
  const structured = segmentThreeDays.map(({ date, rain, windSpeed, humidity, temperature, stormIndex, landslideIndex, lightning }) => ({
    date: moment(date),
    rain: Math.round(rain),
    period: toPeriod(moment(date).format('HH')),
    windSpeed: Math.round(windSpeed),
    humidity: Math.round(humidity),
    temperature: Math.round(temperature),
    lightning: Math.round(lightning),
    stormIndex,
    landslideIndex
  }))

  try {
    return {
      chart: structured.map(({ date, temperature, rain }) => ({
        date,
        temperature,
        rain
      })),
      table: zipWith(
        (key, value) => Object.assign({}, { date: key, dates: value }),
        keys(groupByDayAndMonth(structured)),
        values(groupByDayAndMonth(structured))
      )
    }
  } catch (error) {
    throw new Error(error)
  }
}

export const normalizeDaily = ({ periods, points }) => {
  const { forecast } = points
  const dates = periods

  const {
    precipitation,
    // wind_speed_daily_avg,
    mean_wheather_conditions,
    mean_rel_humidity,
    min_rel_humidity,
    max_rel_humidity,
    min_mean_temperature,
    temperature,
    max_mean_temperature,
    min_pressure,
    mean_pressure,
    max_pressure,
    wind_speed,
    max_wind_speed,
    wind_direction,
    // wind_gust_daily_max

  } = forecast

  const normalized = {
    ...normalize({
      precipitation: precipitation,
      pressure: mean_pressure,
      humidity: mean_rel_humidity,
      windSpeed: wind_speed,
      windDirection: wind_direction,
      temperature: temperature,
      weatherCondition: mean_wheather_conditions
    }),
    minHumidity: pipe(
      slicer,
      map(parseInt)
    )(min_rel_humidity),
    maxHumidity: pipe(
      slicer,
      map(parseInt)
    )(max_rel_humidity),
    minTemperature: pipe(
      slicer,
      map(parseInt)
    )(min_mean_temperature),
    maxTemperature: pipe(
      slicer,
      map(parseInt)
    )(max_mean_temperature),
    maxWindSpeed: pipe(
      slicer,
      map(Math.round)
    )(max_wind_speed),
    maxWindDirection: pipe(
      slicer,
      map(Math.round)
    )(wind_direction),
    minPressure: pipe(
      slicer,
      map(
        pipe(
          toString,
          slice(0, 4),
          parseInt
        )
      )
    )(min_pressure),
    maxPressure: pipe(
      slicer,
      map(
        pipe(
          toString,
          slice(0, 4),
          parseInt
        )
      )
    )(max_pressure)
  }

  const structured = dates.map((date, index) => ({
    date: moment(date),
    rain: normalized.precipitation[index],
    pressure: normalized.pressure[index],
    period: toPeriod(moment(date).format('HH')),
    condition: {
      initial: toInitial(
        normalized.weatherCondition[index],
        moment(date).format('HH')
      ),
      description: toDescription(normalized.weatherCondition[index])
    },
    wind: {
      maxDirection: normalized.maxWindDirection[index],
      maxSpeed: normalized.maxWindSpeed[index],
      direction: normalized.windDirection[index],
      speed: normalized.windSpeed[index]
    },
    humidity: normalized.humidity[index],
    minHumidity: normalized.minHumidity[index],
    minPressure: normalized.minPressure[index],
    maxPressure: normalized.maxPressure[index],
    maxHumidity: normalized.maxHumidity[index],
    minTemperature: normalized.minTemperature[index],
    maxTemperature: normalized.maxTemperature[index],
    temperature: normalized.temperature[index],
    windSpeed: normalized.windSpeed[index]
  }))

  try {
    return {
      chart: dates.map((date, index) => ({
        date,
        minTemperature: normalized.minTemperature[index],
        maxTemperature: normalized.maxTemperature[index],
        temperature: normalized.temperature[index],
        rain: normalized.precipitation[index]
      })),
      table: zipWith(
        (key, value) => Object.assign({}, { date: key, dates: value }),
        keys(groupByDayAndMonth(structured)),
        values(groupByDayAndMonth(structured))
      )
    }
  } catch (error) {
    throw new Error(error)
  }
}

const normalize = ({
  precipitation,
  pressure,
  humidity,
  windSpeed,
  windDirection,
  temperature,
  weatherCondition
}) => ({
  temperature: map(parseInt, temperature),
  precipitation: map(toFixed, precipitation),
  weatherCondition,
  humidity: pipe(
    slicer,
    map(parseInt)
  )(humidity),
  pressure: pipe(
    slicer,
    map(
      pipe(
        toString,
        slice(0, 4),
        parseInt
      )
    )
  )(pressure),
  windSpeed: pipe(
    slicer,
    map(Math.round)
  )(windSpeed),
  windDirection: pipe(
    slicer,
    map(degToCompass)
  )(windDirection)
})
