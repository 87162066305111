import * as yup from 'yup'

export const validateFifteenDaysWithPeriods = async data => {
  console.log('AQUi')
  const type = yup
    .array()
    .of(yup.number().nullable())
    .min(60)
    .max(61)
    .required()

  const schema = yup.object({
    periods: yup
      .array()
      .of(yup.string().nullable())
      .min(60)
      .max(61)
      .required(),
    points: yup.object({
      forecast: yup.object({
        precipitation: type,
        pressure: type,
        rel_humidity: type,
        temperature: type,
        wind_speed: type,
        // wind_gust: type,
        wind_direction: type,
        wheather_conditions: type
      })
    })
  })

  const isValid = await schema.isValid(data)

  if (!isValid) {
    const { message } = await schema.validate(data)

    throw new Error(message)
  }

  return data
}

export const validateFifteenDays = async data => {
  const type = yup
    .array()
    .of(yup.number().nullable())
    .min(15)
    .max(16)
    .required()

  const schema = yup.object({
    periods: yup
      .array()
      .of(yup.string().nullable())
      .min(15)
      .max(16)
      .required(),
    points: yup.object({
      forecast: yup.object({
        precipitation: type,
        // wind_speed_daily_avg: type,
        mean_wheather_conditions: type,
        mean_rel_humidity: type,
        min_rel_humidity: type,
        max_rel_humidity: type,
        min_mean_temperature: type,
        temperature: type,
        max_mean_temperature: type,
        min_pressure: type,
        mean_pressure: type,
        max_pressure: type,
        wind_speed: type,
        max_wind_speed: type,
        wind_direction: type,
        // wind_gust_daily_max: type
      })
    })
  })

  const isValid = await schema.isValid(data)

  if (!isValid) {
    const { message } = await schema.validate(data)

    throw new Error(message)
  }

  return data
}
