import { Env } from "../environment"
import { toJSON } from "./utils"

const CLIMATEMPO_API = resource => `${Env.getEnv(Env.CLIMATEMPO_API)}/${resource}`
const CLIMATEMPO_CONFIG = {
  method: 'POST',
  body: new URLSearchParams({
    'userId': Env.getEnv(Env.CLIMATEMPO_USER_ID),
    'client': Env.getEnv(Env.CLIMATEMPO_CLIENT),
    'secret': Env.getEnv(Env.CLIMATEMPO_SECRET)
  }),
  cache: 'no-store'
}

function normalizeUsers({ data }) {
  return data.map((user) => {
    return {
      user
    }
  })
}

export function fetchUsers() {
  return fetch(CLIMATEMPO_API('order/users'), CLIMATEMPO_CONFIG)
    .then(toJSON)
    .then(normalizeUsers)
}

export function fetchCreateUser({name, userLogin, password, isAdmin, gender, email}) {
  return fetch(CLIMATEMPO_API('user/create'), {
    method: 'POST',
    body: new URLSearchParams({
      'userId': Env.getEnv(Env.CLIMATEMPO_USER_ID),
      'client': Env.getEnv(Env.CLIMATEMPO_CLIENT),
      'secret': Env.getEnv(Env.CLIMATEMPO_SECRET),
      'clientId': Env.getEnv(Env.CLIMATEMPO_USER_ID),
      name,
      userLogin,
      password,
      isAdmin,
      gender,
      email
    }),
    
  })
  .then(toJSON)
}

export function fetchUpdateUser({userId, name, userLogin, password, isAdmin, gender, email}) {
  return fetch(CLIMATEMPO_API('user/edit'), {
    method: 'POST',
    body: new URLSearchParams({
      userId,
      'client': Env.getEnv(Env.CLIMATEMPO_CLIENT),
      'secret': Env.getEnv(Env.CLIMATEMPO_SECRET),
      'clientId': Env.getEnv(Env.CLIMATEMPO_USER_ID),
      name,
      userLogin,
      password,
      isAdmin,
      gender,
      email
    }),
    
  })
  .then(toJSON)
}

export function fetchDeleteUser(dataUser, showMessageUp) {
  if(dataUser.id === 3030 && dataUser.login === "ntsgas"){
    showMessageUp('Não foi possível excluir este usuário!', 'error')
    return
  }
  return fetch(CLIMATEMPO_API('user/delete'), {
    method: 'POST',
    body: new URLSearchParams({
      userIds: dataUser.id,
      'client': Env.getEnv(Env.CLIMATEMPO_CLIENT),
      'secret': Env.getEnv(Env.CLIMATEMPO_SECRET),
    })
  })
  .then(toJSON)
}

export function fetchSendEmail({destination, userLogin, password, name}) {
  return fetch(CLIMATEMPO_API('notifications/email'), {
    method: 'POST',
    body: new URLSearchParams({
      'userId': Env.getEnv(Env.CLIMATEMPO_USER_ID),
      'client': Env.getEnv(Env.CLIMATEMPO_CLIENT),
      'secret': Env.getEnv(Env.CLIMATEMPO_SECRET),
      'clientId': Env.getEnv(Env.CLIMATEMPO_USER_ID),
      name,
      subject: "Seja bem vindo ao aplicativo da NTS",
      template: "NTS",
      userLogin,
      password,
      destination
    }),
    
  })
  .then(toJSON)
}