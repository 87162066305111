import { map, concat } from 'ramda'
import { toJSON } from './utils'
import { Env } from 'tbg/environment'

import cities from 'tbg/resources/forecasts/cities.json'

const URI = resource => `${Env.getEnv(Env.BROLY_URI)}/${resource}`
const NTS_API_URL = resource => `${Env.getEnv(Env.NTS_API)}/${resource}`

const defaultConfig = {
  headers: {
    'x-api-key': Env.getEnv(Env.BROLY_KEY)
  }
}

const normalizeById = points =>
  map(
    ({ name_id, latitude, longitude, type, description }) => ({
      value: {
        id: name_id,
        coordinates: [latitude, longitude]
      },
      text: description,
      category: type
    }),
    points
  )

const normalizeByCoordinates = points =>
  map(
    (segment) => ({
      value: segment,
      text: `Segmento ${segment}`,
      category: 'SEGMENT'
    }),
    points.names
  )

const normalizeCities = () =>
  map(
    ({ lat, lon, name, state }) => ({
      value: [lat, lon],
      text: `${name}-${state}`,
      category: 'city'
    }),
    cities.features
  )

export const fetchCitiesAndPoints = async () => {
  const mergePointsWithCities = points => concat(points, normalizeCities())
  
  return fetchPointsByCoordinates().then(mergePointsWithCities)
}

export function fetchPointsByCoordinates() {
  return fetch(
    NTS_API_URL('segments/all')
    )
      .then(toJSON)
      .then(normalizeByCoordinates)
}

export function fetchPointsById() {
  return fetch(URI('points'), defaultConfig)
    .then(toJSON)
    .then(normalizeById)
}
