import React, { useContext } from 'react'
import v4 from 'uuid/v4'
import { useStore, useStoreActions, useStoreState } from 'easy-peasy'
import { groupBy } from 'ramda'
import { ThemeContext } from 'styled-components'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { CAPTION } from 'tbg/config'

import {
  Icon,
  Switch,
  Text,
  Navigation as Navigable,
  Range,
  Radio,
  Checkbox,
  Autocomplete,
  Tooltip,
  Box
} from '@somarmeteorologia/momentum'

const { Type, Interable, Title, Control, Separator } = Navigable

const categories = [
  {
    id: 'SEGMENT',
    text: 'Segmentos',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  }
]

const fullyCategories = [
  ...categories,
  {
    id: 'city',
    text: 'Cidades',
    selected: false,
    icon: ({ width, height, color }) => (
      <Icon name="city" width={width} height={height} color={color} />
    )
  }
]

const groupByRegion = groupBy(radar => radar.region)

const Container = styled(Box)`
  display: flex;
  align-items: end;
`

const Structure = ({ toOpenToast, children, history }) => {
  const { text } = useContext(ThemeContext)

  const { forecasts, organs, layers, filters, radars } = useStore(
    ({ radars, forecasts, pipelines, organs, config, filters }) => ({
      toOpen: config.toOpen,
      radars: radars.data,
      forecasts: forecasts.layers,
      organs: organs.data,
      layers: pipelines.layers,
      filters: filters.data
    })
  )

  const { setShowValves, setShowCompressionStations, setShowDeliveryPoints, setShowReceivingPoints } = useStoreActions(({ pipelines }) => pipelines)
  const { showValves, showCompressionStations, showDeliveryPoints, showReceivingPoints } = useStoreState(({ pipelines }) => pipelines)

  const { toggle } = useStoreActions(({ forecasts }) => forecasts)
  const { setCaption } = useStoreActions(({ map }) => map)

  const groupedRadars = groupByRegion(radars)
  const regions = Object.keys(groupedRadars).sort((a, b) => (a < b ? 1 : -1))

  const structure = [
    {
      id: 'monitoring',
      type: Type.Category,
      title: ({ details, description }) => (
        <>
          <Icon name="home" right={10} width={20} height={20} color={details} />
          <Text
            weight={Text.weight.bold}
            size={Text.size.fourteen}
            color={description}
          >
            Monitoramento
          </Text>
        </>
      ),
      children: [
        {
          id: 'pipelines',
          parent: 'monitoring',
          prevent: ({ getter }) => !getter('togglePipelines'),
          onPrevent: () => toOpenToast(),
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="togglePipelines"
                  label="Meus Locais"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('togglePipelines')}
                  onChange={() =>
                    setter('togglePipelines', !getter('togglePipelines'))
                  }
                />
              </>
            </Interable>
          ),
          type: Type.Group,
          children: [
            {
              id: 'notablePoints',
              type: Type.Item,
              parent: 'pipelines',
              title: ({ getter, setter }) => (
                <>
                  <Text
                    bottom={15}
                    size={Text.size.fourteen}
                    weight={Text.weight.bold}
                  >
                    Pontos Notáveis
                  </Text>
                  <div style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}>
                    <Checkbox
                      id="toggleValves"
                      labelAlign={Checkbox.labelAlign.right}
                      size={Checkbox.size.small}
                      onChange={value => setter('toggleValves', value)}
                      checked={getter('toggleValves')}
                      label="Válvulas"
                      bottom={10}
                    />
                    <Checkbox
                      id="toggleCompressionStations"
                      labelAlign={Checkbox.labelAlign.right}
                      size={Checkbox.size.small}
                      onChange={value => setter('toggleCompressionStations', value)}
                      checked={getter('toggleCompressionStations')}
                      label="Estações de Compressão"
                      bottom={10}
                    />
                    <Checkbox
                      id="toggleDeliveryPoints"
                      labelAlign={Checkbox.labelAlign.right}
                      size={Checkbox.size.small}
                      onChange={value => setter('toggleDeliveryPoints', value)}
                      checked={getter('toggleDeliveryPoints')}
                      label="Pontos de Entrega"
                      bottom={10}
                    />
                    <Checkbox
                      id="toggleReceivingPoints"
                      labelAlign={Checkbox.labelAlign.right}
                      size={Checkbox.size.small}
                      onChange={value => setter('toggleReceivingPoints', value)}
                      checked={getter('toggleReceivingPoints')}
                      label="Pontos de Recebimento"
                      bottom={20}
                    />
                  </div>
                </>
              ),
              children: []
            },
            {
              id: 'pipelinesContent',
              type: Type.Item,
              parent: 'pipelines',
              title: ({ getter, setter }) => (
                <>
                  <Text
                    bottom={15}
                    size={Text.size.fourteen}
                    weight={Text.weight.bold}
                  >
                    Segmentos
                  </Text>
                  {layers.map(({ id, name }) => (
                    <Checkbox
                      key={v4()}
                      id={id}
                      labelAlign={Checkbox.labelAlign.right}
                      size={Checkbox.size.small}
                      onChange={value => setter(id, value)}
                      checked={getter(id)}
                      label={name}
                      bottom={10}
                    />
                  ))}
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'nowLabel',
          parent: 'monitoring',
          title: () => (
            <Text
              weight={Text.weight.bold}
              size={Text.size.ten}
              color={text.quaternary}
            >
              AGORA
            </Text>
          ),
          type: Type.Item,
          children: []
        },
        {
          id: 'satellite',
          parent: 'monitoring',
          prevent: ({ getter }) => !getter('toggleSatellite'),
          onPrevent: () => toOpenToast(),
          title: ({ getter, setter }) => (
            <Interable>
              <Switch
                id="toggleSatellite"
                label="Nuvens Agora"
                labelAlign="right"
                size={Switch.size.large}
                active={getter('toggleSatellite')}
                onChange={() =>
                  setter('toggleSatellite', !getter('toggleSatellite'))
                }
              />
            </Interable>
          ),
          type: Type.Group,
          children: [
            {
              id: 'satelliteContent',
              type: Type.Item,
              parent: 'satellite',
              title: ({ getter, setter }) => (
                <Control>
                  <Range
                    label="Opacidade"
                    bottom={25}
                    id="satellitesOpacity"
                    value={getter('satellitesOpacity')}
                    onChange={value => setter('satellitesOpacity', value)}
                  />
                </Control>
              ),
              children: []
            }
          ]
        },
        {
          id: 'radars',
          parent: 'monitoring',
          prevent: ({ getter }) => !getter('toggleRadars'),
          onPrevent: () => toOpenToast(),
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="toggleRadars"
                  label="Chuva Agora"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('toggleRadars')}
                  onChange={() =>
                    setter('toggleRadars', !getter('toggleRadars'))
                  }
                />
              </>
            </Interable>
          ),
          type: Type.Group,
          children: [
            {
              id: 'radarsContent',
              type: Type.Item,
              parent: 'radars',
              title: ({ getter, setter }) => (
                <>
                  <Control>
                    <Range
                      label="Opacidade"
                      bottom={25}
                      id="radarsOpacity"
                      value={getter('radarsOpacity')}
                      onChange={value => setter('radarsOpacity', value)}
                    />
                  </Control>

                  {regions.map(region => (
                    <div key={v4()}>
                      <Text
                        weight={Text.weight.bold}
                        size={Text.size.ten}
                        color={text.quaternary}
                        bottom={10}
                        top={10}
                      >
                        {region}
                      </Text>

                      {groupedRadars[region].map(
                        ({ id, name, type, last_update, last_image }) => {
                          const date = moment(last_update).format('DD/MM/YY')
                          const time = moment(last_update).format('HH:mm')

                          return (
                            <Container key={id} bottom={10}>
                              <Switch
                                key={id}
                                id={id}
                                right={10}
                                label={name}
                                labelAlign={Switch.labelAlign.right}
                                active={getter(id)}
                                disabled={last_image == 'null'}
                                onChange={() => setter(id, !getter(id))}
                              />
                              {last_image != 'null' && (
                                <Tooltip
                                  body={({ color }) => (
                                    <Text color={color}>
                                      Atualizado em {date} às {time}
                                    </Text>
                                  )}
                                  header={({ color }) => (
                                    <Text color={color}>{type}</Text>
                                  )}
                                >
                                  <Icon
                                    name="info"
                                    width={15}
                                    height={15}
                                    color={text.quaternary}
                                  />
                                </Tooltip>
                              )}
                            </Container>
                          )
                        }
                      )}
                    </div>
                  ))}
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'stations',
          parent: 'monitoring',
          prevent: ({ getter }) => !getter('toggleStations'),
          onPrevent: () => toOpenToast(),
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="toggleStations"
                  label="Estações Meteorológicas"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('toggleStations')}
                  onChange={() =>
                    setter('toggleStations', !getter('toggleStations'))
                  }
                />
              </>
            </Interable>
          ),
          type: Type.Group,
          children: [
            {
              id: 'stationsContent',
              type: Type.Item,
              parent: 'stations',
              title: ({ getter, setter }) => (
                <>
                  <Control>
                    <Radio
                      name="type"
                      labelAlign={Radio.labelAlign.right}
                      size={Radio.size.small}
                      orientation={Radio.orientation.column}
                      onChange={value => setter('stationsType', value)}
                    >
                      <Radio.Option
                        id="wind"
                        label="Velocidade do Vento (km/h)"
                        bottom={10}
                      />
                      <Radio.Option
                        id="precipitation"
                        label="Chuva (mm)"
                        checked={true}
                        bottom={10}
                      />
                      <Radio.Option
                        id="temperature"
                        label="Temperatura (°C)"
                        bottom={10}
                      />
                      <Radio.Option
                        id="humidity"
                        label="Umidade (%)"
                        bottom={10}
                      />
                    </Radio>
                  </Control>

                  <Text
                    size={Text.size.twelve}
                    weight={Text.weight.bold}
                    bottom={5}
                  >
                    Estações Meteorológicas
                  </Text>

                  {Object.values(organs).map(({ id, label }) => (
                    <Checkbox
                      key={v4()}
                      id={id}
                      labelAlign={Checkbox.labelAlign.right}
                      size={Checkbox.size.small}
                      onChange={value => setter(id, value)}
                      checked={getter(id)}
                      label={label}
                      bottom={10}
                    />
                  ))}
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'lightning',
          parent: 'monitoring',
          prevent: () => true,
          type: Type.Group,
          title: ({ getter, setter }) => (
            <Interable>
              <Switch
                id="toggleLightning"
                label="Raios"
                labelAlign="right"
                size={Switch.size.large}
                active={getter('toggleLightning')}
                onChange={() =>
                  setter('toggleLightning', !getter('toggleLightning'))
                }
              />
            </Interable>
          ),
          children: []
        },
        {
          id: 'fires',
          parent: 'monitoring',
          prevent: () => true,
          type: Type.Group,
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="toggleFires"
                  label="Queimadas"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('toggleFires')}
                  onChange={() => setter('toggleFires', !getter('toggleFires'))}
                />
              </>
            </Interable>
          ),
          children: []
        },
        {
          id: 'forecastsLabel',
          parent: 'monitoring',
          title: () => (
            <Text
              weight={Text.weight.bold}
              size={Text.size.ten}
              color={text.quaternary}
            >
              PREVISÕES
            </Text>
          ),
          type: Type.Item,
          children: []
        },
        {
          id: 'forecasts',
          parent: 'monitoring',
          prevent: ({ getter }) => !getter('toggleForecasts'),
          onPrevent: () => toOpenToast(),
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="toggleForecasts"
                  label="Previsões WRF"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('toggleForecasts')}
                  onChange={() =>
                    setter('toggleForecasts', !getter('toggleForecasts'))
                  }
                />
              </>
            </Interable>
          ),
          type: Type.Group,
          children: [
            {
              id: 'forecastsContent',
              type: Type.Item,
              parent: 'forecasts',
              title: ({ getter, setter }) => (
                <>
                  <Control>
                    <Range
                      label="Opacidade"
                      bottom={25}
                      id="forecastsOpacity"
                      value={getter('forecastsOpacity')}
                      onChange={value => setter('forecastsOpacity', value)}
                    />
                  </Control>

                  {forecasts.map(({ id, name, info, active }) => (
                    <Container key={id} bottom={10}>
                      <Switch
                        id={id}
                        label={name}
                        labelAlign={Switch.labelAlign.right}
                        active={forecasts.find(item => item.id === id).active}
                        onChange={value => {
                          toggle({ forecasts, id })
                          setCaption(value ? CAPTION[id] : null)
                        }}
                        right={10}
                      />
                      {info && (
                        <Tooltip
                          size={Tooltip.size.small}
                          header={({ color }) => (
                            <Text color={color}>{name}</Text>
                          )}
                          body={({ color }) => (
                            <Text color={color}>{info}</Text>
                          )}
                        >
                          <Icon
                            name="info"
                            width={15}
                            height={15}
                            color={text.quaternary}
                          />
                        </Tooltip>
                      )}
                    </Container>
                  ))}
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'forecast',
          parent: 'monitoring',
          type: Type.Group,
          onBack: () => history.push('/'),
          onClick: () => history.push('/forecasts'),
          title: ({ details }) => (
            <Title>
              <Icon
                name="barChart"
                right={10}
                left={5}
                width={20}
                height={20}
                color={details}
              />
              <Text size={Text.size.fourteen} weight={Text.weight.light}>
                Previsões Pontuais
              </Text>
            </Title>
          ),
          children: [
            {
              id: 'forecastContent',
              parent: 'forecast',
              type: Type.Item,
              title: ({ setter, getter }) => (
                <>
                  <Text
                    top={15}
                    bottom={15}
                    size={Text.size.fourteen}
                    weight={Text.weight.bold}
                  >
                    Tipos de previsões
                  </Text>

                  <Radio
                    name="type"
                    labelAlign={Radio.labelAlign.right}
                    size={Radio.size.small}
                    orientation={Radio.orientation.column}
                    onChange={value => setter('forecastType', value)}
                  >
                    <Radio.Option
                      data-testid="hourly"
                      id="hourly"
                      label="Avisos 72 horas (Tabelas)"
                      bottom={15}
                      checked={false}
                    />
                    <Radio.Option
                      data-testid="map"
                      id="map"
                      label="Avisos 72 horas (Mapas)"
                      bottom={15}
                      checked={true}
                    />
                    <Radio.Option
                      data-testid="period"
                      id="period"
                      label="Por período (3 dias)"
                      checked={false}
                      bottom={15}
                    />

                    <Radio.Option
                      id="daily"
                      label="Diária (15 dias)"
                      checked={false}
                      bottom={15}
                    />
                  </Radio>

                  <Separator />

                  <Text
                    data-testid="typeTitle"
                    top={15}
                    bottom={15}
                    size={Text.size.fourteen}
                    weight={Text.weight.bold}
                  >
                    {getter('isAutocompleteVisible')
                      ? 'Buscar por ponto de interesse ou cidade'
                      : 'Segmentos'}
                  </Text>

                  {getter('isAutocompleteVisible') && (
                    <Autocomplete
                      data-testid="autocomplete"
                      full={true}
                      onChange={value => setter('locality', value)}
                      categories={fullyCategories}
                      options={getter('citiesAndPoints')}
                    />
                  )}

                  {!getter('isAutocompleteVisible') &&
                    Object.values(filters).map(({ id, name }) => (
                      <Checkbox
                        data-testid={`filter-${name}`}
                        key={v4()}
                        id={id}
                        labelAlign={Checkbox.labelAlign.right}
                        size={Checkbox.size.small}
                        onChange={value => setter(id, value)}
                        checked={getter(id)}
                        label={name}
                        bottom={10}
                      />
                    ))}
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'recordsLabel',
          parent: 'monitoring',
          title: () => (
            <Text
              weight={Text.weight.bold}
              size={Text.size.ten}
              color={text.quaternary}
            >
              HISTÓRICOS
            </Text>
          ),
          type: Type.Item,
          children: []
        },
        {
          id: 'rain',
          parent: 'monitoring',
          prevent: () => true,
          type: Type.Group,
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="toggleRain"
                  label="Chuvas Observadas"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('toggleRain')}
                  onChange={() => setter('toggleRain', !getter('toggleRain'))}
                />
              </>
            </Interable>
          ),
          children: []
        },
        {
          id: 'reports',
          parent: 'monitoring',
          type: Type.Group,
          onBack: () => history.push('/'),
          onClick: () => history.push('/reports'),
          title: ({ details }) => (
            <Title>
              <Icon
                name="newsletter"
                right={10}
                left={5}
                width={20}
                height={20}
                color={details}
              />
              <Text size={Text.size.fourteen} weight={Text.weight.light}>
                Boletins
              </Text>
            </Title>
          ),
          children: []
        }
      ]
    }
  ]

  return <>{children({ structure })}</>
}

Structure.propTypes = {
  toOpenToast: PropTypes.func.isRequired
}

export default withRouter(Structure)
