export const MODULE = {
  monitoring: {
    id: 'monitoring',
    title: 'Monitoramento'
  }
}

export const PAGE = {
  map: {
    id: 'map',
    title: 'Mapa',
    path: '/'
  },
  reports: {
    id: 'reports',
    title: 'Boletins',
    path: '/reports'
  },
  forecast: {
    id: 'forecast',
    title: 'Previsões Pontuais',
    path: '/forecasts'
  },
  records: {
    id: 'records',
    title: 'Registros Históricos',
    path: '/records'
  },
  users: {
    id: 'users',
    title: 'Usuários',
    path: '/users'
  }
}
export const LAYER = {
  mono: {
    id: 'mono',
    value: 'light_all'
  },
  color: {
    id: 'color',
    value: 'voyager_labels_under'
  },
  satellite: {
    id: 'satellite',
    value: 'cjxko1zlo04np1cmwe632wh35'
  },
  dark: {
    id: 'dark',
    value: 'dark_all'
  }
}

export const LAYERS = [
  {
    id: 'indcape',
    name: 'Índice CAPE',
    info:
      'Índice representativo da instabilidade da atmosfera: condição de chuva e temporais.',
    active: false
  },
  {
    id: 'indk',
    name: 'Índice K',
    info:
      'Índice indicativo de potencial para ocorrência de chuvas com grande acumulo.',
    active: false
  },
  {
    id: 'indswt',
    name: 'Índice SWEAT',
    info:
      'Índice indicativo da ocorrência de tempestades severas, ventos fortes e até tornados.',
    active: false
  },
  {
    id: 'indtt',
    name: 'Índice Total Totals',
    info: 'Índice indicativo de potencial para descargas elétricas.',
    active: false
  },
  {
    id: 'precacum',
    name: 'Precipitação Acumulada',
    info: null,
    active: false
  },
  {
    id: 'rh',
    name: 'Umidade Relativa',
    info: null,
    active: false
  },
  {
    id: 't2m',
    name: 'Temperatura',
    info: null,
    active: false
  },
  {
    id: 'uv10',
    name: 'Vento',
    info: null,
    active: false
  }
]

export const PIPELINES = [
  {
    id: 'guideline',
    name: 'Diretriz',
    active: true
  },
  // {
  //   id: 'GASAN',
  //   name: 'GASAN',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASANII',
  //   name: 'GASANII',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASBEL',
  //   name: 'GASBEL',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASBELII',
  //   name: 'GASBELII',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASCAR',
  //   name: 'GASCAR',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASDUCIII',
  //   name: 'GASDUCIII',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASPAJ',
  //   name: 'GASPAJ',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASPAL',
  //   name: 'GASPAL',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASTAU',
  //   name: 'GASTAU',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASVOL_ESVOL',
  //   name: 'GASVOL_ESVOL',
  //   active: false,
  //   isGroup: true,
  // },
  // {
  //   id: 'GASVOL',
  //   name: 'GASVOL',
  //   active: false,
  //   isGroup: true,
  // },
  {
    id: 'GASAN_KM0',
    name: 'GASAN_KM0',
    active: false
  },
  {
    id: 'GASAN_KM10',
    name: 'GASAN_KM10',
    active: false
  },
  {
    id: 'GASAN_KM20',
    name: 'GASAN_KM20',
    active: false
  },
  {
    id: 'GASAN_KM30',
    name: 'GASAN_KM30',
    active: false
  },
  {
    id: 'GASAN_KM40',
    name: 'GASAN_KM40',
    active: false
  },
  {
    id: 'GASANII_KM0',
    name: 'GASANII_KM0',
    active: false
  },
  {
    id: 'GASANII_KM10',
    name: 'GASANII_KM10',
    active: false
  },
  {
    id: 'GASANII_KM20',
    name: 'GASANII_KM20',
    active: false
  },
  {
    id: 'GASANII_KM30',
    name: 'GASANII_KM30',
    active: false
  },
  // {
  //   id: 'GASBEL_KM0',
  //   name: 'GASBEL_KM0',
  //   active: false
  // },
  {
    id: 'GASBEL_KM10',
    name: 'GASBEL_KM10',
    active: false
  },
  {
    id: 'GASBEL_KM20',
    name: 'GASBEL_KM20',
    active: false
  },
  {
    id: 'GASBEL_KM30',
    name: 'GASBEL_KM30',
    active: false
  },
  {
    id: 'GASBEL_KM40',
    name: 'GASBEL_KM40',
    active: false
  },
  {
    id: 'GASBEL_KM50',
    name: 'GASBEL_KM50',
    active: false
  },
  {
    id: 'GASBEL_KM60',
    name: 'GASBEL_KM60',
    active: false
  },
  {
    id: 'GASBEL_KM70',
    name: 'GASBEL_KM70',
    active: false
  },
  {
    id: 'GASBEL_KM80',
    name: 'GASBEL_KM80',
    active: false
  },
  {
    id: 'GASBEL_KM90',
    name: 'GASBEL_KM90',
    active: false
  },
  {
    id: 'GASBEL_KM100',
    name: 'GASBEL_KM100',
    active: false
  },
  {
    id: 'GASBEL_KM110',
    name: 'GASBEL_KM110',
    active: false
  },
  {
    id: 'GASBEL_KM120',
    name: 'GASBEL_KM120',
    active: false
  },
  {
    id: 'GASBEL_KM130',
    name: 'GASBEL_KM130',
    active: false
  },
  {
    id: 'GASBEL_KM140',
    name: 'GASBEL_KM140',
    active: false
  },
  {
    id: 'GASBEL_KM150',
    name: 'GASBEL_KM150',
    active: false
  },
  {
    id: 'GASBEL_KM160',
    name: 'GASBEL_KM160',
    active: false
  },
  {
    id: 'GASBEL_KM170',
    name: 'GASBEL_KM170',
    active: false
  },
  {
    id: 'GASBEL_KM180',
    name: 'GASBEL_KM180',
    active: false
  },
  {
    id: 'GASBEL_KM190',
    name: 'GASBEL_KM190',
    active: false
  },
  {
    id: 'GASBEL_KM200',
    name: 'GASBEL_KM200',
    active: false
  },
  {
    id: 'GASBEL_KM210',
    name: 'GASBEL_KM210',
    active: false
  },
  {
    id: 'GASBEL_KM220',
    name: 'GASBEL_KM220',
    active: false
  },
  {
    id: 'GASBEL_KM230',
    name: 'GASBEL_KM230',
    active: false
  },
  {
    id: 'GASBEL_KM240',
    name: 'GASBEL_KM240',
    active: false
  },
  {
    id: 'GASBEL_KM250',
    name: 'GASBEL_KM250',
    active: false
  },
  {
    id: 'GASBEL_KM260',
    name: 'GASBEL_KM260',
    active: false
  },
  {
    id: 'GASBEL_KM270',
    name: 'GASBEL_KM270',
    active: false
  },
  {
    id: 'GASBEL_KM280',
    name: 'GASBEL_KM280',
    active: false
  },
  {
    id: 'GASBEL_KM290',
    name: 'GASBEL_KM290',
    active: false
  },
  {
    id: 'GASBEL_KM300',
    name: 'GASBEL_KM300',
    active: false
  },
  {
    id: 'GASBEL_KM310',
    name: 'GASBEL_KM310',
    active: false
  },
  {
    id: 'GASBEL_KM320',
    name: 'GASBEL_KM320',
    active: false
  },
  {
    id: 'GASBEL_KM330',
    name: 'GASBEL_KM330',
    active: false
  },
  {
    id: 'GASBEL_KM340',
    name: 'GASBEL_KM340',
    active: false
  },
  {
    id: 'GASBEL_KM350',
    name: 'GASBEL_KM350',
    active: false
  },
  {
    id: 'GASBEL_KM360',
    name: 'GASBEL_KM360',
    active: false
  },
  {
    id: 'GASBEL_KM370',
    name: 'GASBEL_KM370',
    active: false
  },
  {
    id: 'GASBELII_KM0',
    name: 'GASBELII_KM0',
    active: false
  },
  {
    id: 'GASBELII_KM10',
    name: 'GASBELII_KM10',
    active: false
  },
  {
    id: 'GASBELII_KM20',
    name: 'GASBELII_KM20',
    active: false
  },
  {
    id: 'GASBELII_KM30',
    name: 'GASBELII_KM30',
    active: false
  },
  {
    id: 'GASBELII_KM40',
    name: 'GASBELII_KM40',
    active: false
  },
  {
    id: 'GASBELII_KM50',
    name: 'GASBELII_KM50',
    active: false
  },
  {
    id: 'GASBELII_KM60',
    name: 'GASBELII_KM60',
    active: false
  },
  {
    id: 'GASCAR_KM10',
    name: 'GASCAR_KM10',
    active: false
  },
  {
    id: 'GASCAR_KM20',
    name: 'GASCAR_KM20',
    active: false
  },
  {
    id: 'GASCAR_KM30',
    name: 'GASCAR_KM30',
    active: false
  },
  {
    id: 'GASCAR_KM40',
    name: 'GASCAR_KM40',
    active: false
  },
  {
    id: 'GASCAR_KM50',
    name: 'GASCAR_KM50',
    active: false
  },
  {
    id: 'GASCAR_KM60',
    name: 'GASCAR_KM60',
    active: false
  },
  {
    id: 'GASCAR_KM70',
    name: 'GASCAR_KM70',
    active: false
  },
  {
    id: 'GASCAR_KM80',
    name: 'GASCAR_KM80',
    active: false
  },
  {
    id: 'GASCAR_KM90',
    name: 'GASCAR_KM90',
    active: false
  },
  {
    id: 'GASCAR_KM100',
    name: 'GASCAR_KM100',
    active: false
  },
  {
    id: 'GASCAR_KM110',
    name: 'GASCAR_KM110',
    active: false
  },
  {
    id: 'GASCAR_KM120',
    name: 'GASCAR_KM120',
    active: false
  },
  {
    id: 'GASCAR_KM130',
    name: 'GASCAR_KM130',
    active: false
  },
  {
    id: 'GASCAR_KM140',
    name: 'GASCAR_KM140',
    active: false
  },
  {
    id: 'GASCAR_KM150',
    name: 'GASCAR_KM150',
    active: false
  },
  {
    id: 'GASCAR_KM160',
    name: 'GASCAR_KM160',
    active: false
  },
  {
    id: 'GASCAR_KM170',
    name: 'GASCAR_KM170',
    active: false
  },
  {
    id: 'GASCAR_KM180',
    name: 'GASCAR_KM180',
    active: false
  },
  {
    id: 'GASCAR_KM190',
    name: 'GASCAR_KM190',
    active: false
  },
  {
    id: 'GASDUCIII_KM0',
    name: 'GASDUCIII_KM0',
    active: false
  },
  {
    id: 'GASDUCIII_KM10',
    name: 'GASDUCIII_KM10',
    active: false
  },
  {
    id: 'GASDUCIII_KM20',
    name: 'GASDUCIII_KM20',
    active: false
  },
  {
    id: 'GASDUCIII_KM30',
    name: 'GASDUCIII_KM30',
    active: false
  },
  {
    id: 'GASDUCIII_KM40',
    name: 'GASDUCIII_KM40',
    active: false
  },
  {
    id: 'GASDUCIII_KM50',
    name: 'GASDUCIII_KM50',
    active: false
  },
  {
    id: 'GASDUCIII_KM60',
    name: 'GASDUCIII_KM60',
    active: false
  },
  {
    id: 'GASDUCIII_KM70',
    name: 'GASDUCIII_KM70',
    active: false
  },
  {
    id: 'GASDUCIII_KM80',
    name: 'GASDUCIII_KM80',
    active: false
  },
  {
    id: 'GASDUCIII_KM90',
    name: 'GASDUCIII_KM90',
    active: false
  },
  {
    id: 'GASDUCIII_KM100',
    name: 'GASDUCIII_KM100',
    active: false
  },
  {
    id: 'GASDUCIII_KM110',
    name: 'GASDUCIII_KM110',
    active: false
  },
  {
    id: 'GASDUCIII_KM120',
    name: 'GASDUCIII_KM120',
    active: false
  },
  {
    id: 'GASDUCIII_KM130',
    name: 'GASDUCIII_KM130',
    active: false
  },
  {
    id: 'GASDUCIII_KM140',
    name: 'GASDUCIII_KM140',
    active: false
  },
  {
    id: 'GASDUCIII_KM150',
    name: 'GASDUCIII_KM150',
    active: false
  },
  {
    id: 'GASDUCIII_KM160',
    name: 'GASDUCIII_KM160',
    active: false
  },
  {
    id: 'GASDUCIII_KM170',
    name: 'GASDUCIII_KM170',
    active: false
  },
  {
    id: 'GASDUCIII_KM180',
    name: 'GASDUCIII_KM180',
    active: false
  },
  {
    id: 'GASDUCIII_KM190',
    name: 'GASDUCIII_KM190',
    active: false
  },
  {
    id: 'GASPAJ_KM0',
    name: 'GASPAJ_KM0',
    active: false
  },
  {
    id: 'GASPAJ_KM10',
    name: 'GASPAJ_KM10',
    active: false
  },
  {
    id: 'GASPAJ_KM20',
    name: 'GASPAJ_KM20',
    active: false
  },
  {
    id: 'GASPAJ_KM30',
    name: 'GASPAJ_KM30',
    active: false
  },
  {
    id: 'GASPAJ_KM40',
    name: 'GASPAJ_KM40',
    active: false
  },
  {
    id: 'GASPAJ_KM50',
    name: 'GASPAJ_KM50',
    active: false
  },
  {
    id: 'GASPAJ_KM60',
    name: 'GASPAJ_KM60',
    active: false
  },
  {
    id: 'GASPAJ_KM70',
    name: 'GASPAJ_KM70',
    active: false
  },
  {
    id: 'GASPAJ_KM80',
    name: 'GASPAJ_KM80',
    active: false
  },
  {
    id: 'GASPAJ_KM90',
    name: 'GASPAJ_KM90',
    active: false
  },
  {
    id: 'GASPAL_KM0',
    name: 'GASPAL_KM0',
    active: false
  },
  {
    id: 'GASPAL_KM10',
    name: 'GASPAL_KM10',
    active: false
  },
  {
    id: 'GASPAL_KM20',
    name: 'GASPAL_KM20',
    active: false
  },
  {
    id: 'GASPAL_KM30',
    name: 'GASPAL_KM30',
    active: false
  },
  {
    id: 'GASPAL_KM40',
    name: 'GASPAL_KM40',
    active: false
  },
  {
    id: 'GASPAL_KM50',
    name: 'GASPAL_KM50',
    active: false
  },
  {
    id: 'GASPAL_KM60',
    name: 'GASPAL_KM60',
    active: false
  },
  {
    id: 'GASPAL_KM70',
    name: 'GASPAL_KM70',
    active: false
  },
  {
    id: 'GASPAL_KM80',
    name: 'GASPAL_KM80',
    active: false
  },
  {
    id: 'GASPAL_KM90',
    name: 'GASPAL_KM90',
    active: false
  },
  {
    id: 'GASPAL_KM100',
    name: 'GASPAL_KM100',
    active: false
  },
  {
    id: 'GASPAL_KM110',
    name: 'GASPAL_KM110',
    active: false
  },
  {
    id: 'GASPAL_KM120',
    name: 'GASPAL_KM120',
    active: false
  },
  {
    id: 'GASPAL_KM130',
    name: 'GASPAL_KM130',
    active: false
  },
  {
    id: 'GASPAL_KM140',
    name: 'GASPAL_KM140',
    active: false
  },
  {
    id: 'GASPAL_KM150',
    name: 'GASPAL_KM150',
    active: false
  },
  {
    id: 'GASPAL_KM160',
    name: 'GASPAL_KM160',
    active: false
  },
  {
    id: 'GASPAL_KM170',
    name: 'GASPAL_KM170',
    active: false
  },
  {
    id: 'GASPAL_KM180',
    name: 'GASPAL_KM180',
    active: false
  },
  {
    id: 'GASPAL_KM190',
    name: 'GASPAL_KM190',
    active: false
  },
  {
    id: 'GASPAL_KM200',
    name: 'GASPAL_KM200',
    active: false
  },
  {
    id: 'GASPAL_KM210',
    name: 'GASPAL_KM210',
    active: false
  },
  {
    id: 'GASPAL_KM220',
    name: 'GASPAL_KM220',
    active: false
  },
  {
    id: 'GASPAL_KM230',
    name: 'GASPAL_KM230',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM0',
    name: 'GASPAL_OSVAT_KM0',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM10',
    name: 'GASPAL_OSVAT_KM10',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM20',
    name: 'GASPAL_OSVAT_KM20',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM30',
    name: 'GASPAL_OSVAT_KM30',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM40',
    name: 'GASPAL_OSVAT_KM40',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM50',
    name: 'GASPAL_OSVAT_KM50',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM60',
    name: 'GASPAL_OSVAT_KM60',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM70',
    name: 'GASPAL_OSVAT_KM70',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM80',
    name: 'GASPAL_OSVAT_KM80',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM90',
    name: 'GASPAL_OSVAT_KM90',
    active: false
  },
  {
    id: 'GASPAL_OSVAT_KM100',
    name: 'GASPAL_OSVAT_KM100',
    active: false
  },
  {
    id: 'GASTAU_KM0',
    name: 'GASTAU_KM0',
    active: false
  },
  {
    id: 'GASTAU_KM10',
    name: 'GASTAU_KM10',
    active: false
  },
  {
    id: 'GASTAU_KM20',
    name: 'GASTAU_KM20',
    active: false
  },
  {
    id: 'GASTAU_KM30',
    name: 'GASTAU_KM30',
    active: false
  },
  {
    id: 'GASTAU_KM40',
    name: 'GASTAU_KM40',
    active: false
  },
  {
    id: 'GASTAU_KM50',
    name: 'GASTAU_KM50',
    active: false
  },
  {
    id: 'GASTAU_KM60',
    name: 'GASTAU_KM60',
    active: false
  },
  {
    id: 'GASVOL_ESVOL_KM0',
    name: 'GASVOL_ESVOL_KM0',
    active: false
  },
  {
    id: 'GASVOL_KM0',
    name: 'GASVOL_KM0',
    active: false
  },
  {
    id: 'GASVOL_KM10',
    name: 'GASVOL_KM10',
    active: false
  },
  {
    id: 'GASVOL_KM20',
    name: 'GASVOL_KM20',
    active: false
  },
  {
    id: 'GASVOL_KM30',
    name: 'GASVOL_KM30',
    active: false
  },
  {
    id: 'GASVOL_KM40',
    name: 'GASVOL_KM40',
    active: false
  },
  {
    id: 'GASVOL_KM50',
    name: 'GASVOL_KM50',
    active: false
  },
  {
    id: 'GASVOL_KM60',
    name: 'GASVOL_KM60',
    active: false
  },
  {
    id: 'GASVOL_KM70',
    name: 'GASVOL_KM70',
    active: false
  },
  {
    id: 'GASVOL_KM80',
    name: 'GASVOL_KM80',
    active: false
  },
  {
    id: 'GASVOL_KM90',
    name: 'GASVOL_KM90',
    active: false
  },
  {
    id: 'GASVOL_KM100',
    name: 'GASVOL_KM100',
    active: false
  }
]

export const CAPTION = {
  radars: 'radars',
  satellites: 'satellites',
  indcape: 'indcape',
  indswt: 'indswt',
  indtt: 'indtt',
  indk: 'indk',
  precacum: 'precacum',
  rh: 'rh',
  t2m: 't2m',
  uv10: 'uv10'
}

export const ORGANS = {
  inmet: {
    id: 'inmet',
    label: 'INMET',
    active: true
  },
  cemaden: {
    id: 'cemaden',
    label: 'CEMADEN',
    active: false
  },
  redemet: {
    id: 'redemet',
    label: 'REDEMET',
    active: false
  }
}

export const TYPES = {
  wind: {
    id: 'wind'
  },
  precipitation: {
    id: 'precipitation'
  },
  temperature: {
    id: 'temperature'
  },
  humidity: {
    id: 'humidity'
  }
}

export const FILTERS = {
  SEGMENT: {
    id: 'SEGMENT',
    name: 'Segmentos',
    active: true
  },
  GASAN: {
    id: 'GASAN',
    name: 'GASAN',
    active: false
  },
  GASANII: {
    id: 'GASANII',
    name: 'GASANII',
    active: false
  },
  GASBEL: {
    id: 'GASBEL',
    name: 'GASBEL',
    active: false
  },
  GASBELII: {
    id: 'GASBELII',
    name: 'GASBELII',
    active: false
  },
  GASCAR: {
    id: 'GASCAR',
    name: 'GASCAR',
    active: false
  },
  GASDUCIII: {
    id: 'GASDUCIII',
    name: 'GASDUCIII',
    active: false
  },
  GASPAJ: {
    id: 'GASPAJ',
    name: 'GASPAJ',
    active: false
  },
  GASPAL: {
    id: 'GASPAL',
    name: 'GASPAL',
    active: false
  },
  GASTAU: {
    id: 'GASTAU',
    name: 'GASTAU',
    active: false
  }, 
  GASVOL: {
    id: 'GASVOL',
    name: 'GASVOL',
    active: false
  }
}

export const TABS = {
  period: 'period',
  daily: 'daily',
  map: 'map',
  hourly: 'hourly',
  reports: 'reports',
  hours: 'hours',
  dailys: 'dailys',
  months: 'months',
  users: 'users'
}

export const REPORTS_TYPES = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly'
}
