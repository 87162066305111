import { action, createStore, thunk } from 'easy-peasy'
import { composeWithDevTools } from 'redux-devtools-extension'
import moment from 'moment'

import {
  fetchRadars,
  getAnimatedSatellite,
  getSatellite,
  getWrf,
  getRain
} from 'tbg/services'

import {
  MODULE,
  PAGE,
  LAYERS,
  PIPELINES,
  ORGANS,
  TYPES,
  FILTERS,
  REPORTS_TYPES,
  TABS
} from 'tbg/config'

const state = {
  config: {
    module: MODULE.monitoring,
    page: PAGE.map,
    toOpen: null,
    setModule: action((state, module) => ({
      ...state,
      module
    })),
    setPage: action((state, page) => ({
      ...state,
      page
    })),
    setToOpen: action((state, toOpen) => ({
      ...state,
      toOpen
    }))
  },
  fires: {
    isOpen: false,
    toOpen: action((state, payload) => ({
      ...state,
      isOpen: payload
    }))
  },
  rain: {
    urls: [],
    fetch: action(state => ({
      ...state,
      urls: getRain()
    })),
    isOpen: false,
    toOpen: action((state, payload) => ({
      ...state,
      isOpen: payload
    }))
  },
  radars: {
    data: [],
    persist: action((state, payload) => {
      state.data = payload
    }),
    fetch: thunk(async actions => {
      actions.persist(await fetchRadars())
    }),
    set: action((state, { id, value }) => {
      const radars = state.data.map(item =>
        item.id === id ? { ...item, active: value } : item
      )

      return {
        ...state,
        data: radars
      }
    }),
    isOpen: false,
    toOpen: action((state, payload) => ({
      ...state,
      isOpen: payload
    })),
    opacity: 0.5,
    setOpacity: action((state, payload) => ({
      ...state,
      opacity: payload / 100
    }))
  },
  organs: {
    data: ORGANS,
    type: TYPES.precipitation.id,
    setType: action((state, type) => {
      state.type = type
    }),
    setAsActive: action((state, { id, value }) => {
      state.data[id].active = value
    }),
    setStations: action((state, { id, data }) => {
      state.data[id].data = data
    })
  },
  stations: {
    isOpen: false,
    toOpen: action((state, bool) => ({
      ...state,
      isOpen: bool
    }))
  },
  lightning: {
    isOpen: false,
    type: 'recent',
    toOpen: action((state, bool) => ({
      ...state,
      isOpen: bool
    })),
    setType: action((state, type) => ({
      ...state,
      type
    }))
  },
  wildfire: {
    isOpen: false,
    toOpen: action((state, bool) => ({
      ...state,
      isOpen: bool
    }))
  },
  satellites: {
    url: null,
    isOpen: false,
    toOpen: action((state, payload) => ({
      ...state,
      isOpen: payload
    })),
    opacity: 0.5,
    setOpacity: action((state, payload) => ({
      ...state,
      opacity: payload / 100
    }))
  },
  forecasts: {
    layers: LAYERS,
    toggle: action((state, { forecasts, id }) => {
      let updated = forecasts.map(item => {
        if (item.id === id) {
          item.active = !item.active
        }
        return item
      })

      return {
        ...state,
        urls: [],
        layers: updated
      }
    }),
    set: action((state, { id, value }) => {
      const layers = state.layers.map(item =>
        item.id === id ? { ...item, active: value } : { ...item, active: false }
      )

      return {
        ...state,
        urls: [],
        layers
      }
    }),
    urls: [],
    fetch: action((state, { type, layer }) => ({
      ...state,
      urls: getWrf(type, layer)
    })),
    isOpen: false,
    toOpen: action((state, bool) => ({
      ...state,
      isOpen: bool
    })),
    opacity: 0.5,
    setOpacity: action((state, payload) => ({
      ...state,
      opacity: payload / 100
    })),
    type: 'hour',
    setType: action((state, payload) => ({
      ...state,
      type: payload
    }))
  },
  pipelines: {
    layers: PIPELINES,
    set: action((state, { id, value }) => {
      const layers = state.layers.map(item =>
        item.id === id ? { ...item, active: value } : { ...item }
      )

      return {
        ...state,
        layers
      }
    }),
    isOpen: true,
    toOpen: action((state, payload) => ({
      ...state,
      isOpen: payload
    })),

    showValves: false,
    setShowValves: action((state, payload) => {
      state.showValves = payload
    }),
    
    showCompressionStations: false,
    setShowCompressionStations: action((state, payload) => {
      state.showCompressionStations = payload
    }),
    
    showDeliveryPoints: false,
    setShowDeliveryPoints: action((state, payload) => {
      state.showDeliveryPoints = payload
    }),
    
    showReceivingPoints: false,
    setShowReceivingPoints: action((state, payload) => {
      state.showReceivingPoints = payload
    }),
  },
  map: {
    bounds: [[7, -35], [-58, -78]],
    setBounds: action((state, payload) => ({ ...state, bounds: payload })),
    height: 100,
    setHeight: action((state, payload) => ({ ...state, height: payload })),
    width: 100,
    setWidth: action((state, payload) => ({ ...state, width: payload })),
    caption: null,
    setCaption: action((state, payload) => ({ ...state, caption: payload }))
  },
  forecast: {
    locality: {
      value: [-19.92, -43.94],
      text: 'Belo Horizonte-MG',
      category: 'city'
    },
    type: 'map',
    setType: action((state, type) => ({
      ...state,
      type
    })),
    setLocality: action((state, locality) => ({
      ...state,
      locality
    }))
  },
  tab: {
    value: TABS.map,
    setTab: action((state, value) => ({
      value
    }))
  },
  reports: {
    type: REPORTS_TYPES.daily,
    dates: {
      from: moment(moment().subtract(30, 'days')).format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD')
    },
    setType: action((state, type) => ({ ...state, type })),
    setDates: action((state, dates) => ({ ...state, dates }))
  },
  filters: {
    data: FILTERS,
    set: action((state, { id, value }) => {
      state.data[id].active = value
    })
  },
  records: {
    locality: {
      value: {
        id: 'MS0010N10',
        coordinates: [-19.145268578, -57.786545278]
      },
      text: 'Segmento MS-0010N+10',
      category: 'SEGMENT'
    },
    setLocality: action((state, locality) => ({
      ...state,
      locality
    })),
    type: 'hourly',
    setType: action((state, type) => ({
      ...state,
      type
    }))
  }
}

export const store = createStore(state, {
  compose: composeWithDevTools({ trace: true })
})
