import React, { useEffect, useState } from "react"

import { fetchUsers } from "../../services/users"

import {Button, InputModal} from '../Users/styles'
import { isAuthenticated } from "./isAuthenticated"

export default function AuthUser({history}) {
  
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  
  useEffect(() => {
    isAuthenticated(history, 'login')
  }, [])

  function ensureAuthentcation(){
    fetchUsers().then((data) => {
      const userFound = data.find( ({ user }) => user.login=== login && user.password===password && user.admin) 

      if(userFound) {
        localStorage.setItem('@nts-site/authenticated', JSON.stringify({login, id: userFound.user.id, admin: userFound.user.admin}))
        history.push('/users')
      } 
      else {
        alert('Login ou Senha invalidos.')
      }
       
  })
} 
  return(
    <div style={{display: 'flex', width: '100%', height: '600px',  justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'white'}}>
      <h1>NTS - PORTAL METEOROLÓGICO</h1>
      
      <div style={{display: 'flex', padding: '10px 10px', flexDirection: 'column', alignItems: 'center', marginTop: '50px', width: '400px', height: '350px', border: '1px solid black', borderRadius: '5px', boxShadow: '2px 2px 2px black'}}>
        <p >Faça o login para se autenticar</p>
        <div style={{display: 'flex', width: '100%', flexDirection: 'column', marginTop: '45px', justifyContent: 'space-between'}}>
          Login
          <InputModal onChange={e => setLogin(e.target.value)} value={login}/>
          Senha
          <InputModal type= 'password' onChange={e => setPassword(e.target.value)} value={password} />
        </div>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}>
          <Button width="80px" onClick={ensureAuthentcation}>Entrar</Button>
        </div>
      </div>
    </div>
  )
}