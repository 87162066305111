export default {
  "geometry": {
    "type": "Polygon",
    "coordinates": [
      [
        [
          -48.856201171875,
          -24.186847428521233
        ],
        [
          -40.045166015625,
          -24.186847428521233
        ],
        [
          -40.045166015625,
          -19.508020154916768
        ],
        [
          -48.856201171875,
          -19.508020154916768
        ],
        [
          -48.856201171875,
          -24.186847428521233
        ]
      ]
    ]
  }
}