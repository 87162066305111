import React, { useRef, useEffect, useState } from 'react'
import { renderToString } from 'react-dom/server'
import useInterval from '@use-it/interval'
import moment from 'moment'
 
import PropTypes from 'prop-types'
import { Marker as Markerable } from 'react-leaflet'
import L from 'leaflet'
import Markers from '@somarmeteorologia/react-leaflet-markers'
import { Snackbar, Text } from '@somarmeteorologia/momentum'
 
import { requestLightings } from '../services/lightning'
 
export function Streamable({ isOpen, marker, channel, messages, setMessages }) {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
 
  const toClose = () => setIsSnackbarOpen(false)
 
  const onMessage = message => {
    setIsSnackbarOpen(!message.length > 0)
    const data = message
    setMessages(() => [
      ...data.map(value => ({
        ...value,
        time: moment().toString()
      }))
    ])
  }
 
  const defaultIcon = L.icon({
    html: renderToString(marker),
    iconSize: [15, 15]
  })
 
 
  useEffect(() => {
    requestLightings().then(onMessage)
  }, [])
 
  useInterval(() => {
    requestLightings().then(onMessage)
  }, 300000)
 
  useEffect(() => {
    if (!isOpen) {
      setMessages([])
      setIsSnackbarOpen(false)
    }
  }, [isOpen])
 
  const markers = () => {
    return messages.map(message => {
      const key = message.id
 
      return (
        <Markerable
          key={key}
          icon={defaultIcon}
          position={[message.latitude, message.longitude]}
          properties={{ ...message, key }}
        />
      )
    })
  }
 
  return (
    <>
      {!!messages.length && (
        <Markers dataKey="properties" isDebug={false} markers={markers()} />
      )}
 
      <Snackbar
        data-testid="snackbar"
        duration={3}
        isOpen={isSnackbarOpen}
        toClose={toClose}
        description={({ size, color }) => (
          <Text size={size} color={color}>
            Sem incidência de descargas elétricas na área da NTS neste momento.
          </Text>
        )}
      />
    </>
  )
}
 
Streamable.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  channel: PropTypes.oneOf(['lightnings-tbg', 'lightnings-tbg-2h', 'glm-lightnings']).isRequired,
  messages: PropTypes.array.isRequired,
  setMessages: PropTypes.func.isRequired,
  marker: PropTypes.node.isRequired
}