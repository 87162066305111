import { toJSON } from '../utils'
import { normalizeResume, normalizeForecast, normalizeForecastHourly } from './normalize'
import { Env } from '../../environment';

import { validateGeoResume } from './validate'

const NTS_API_URL = resource => `${Env.getEnv(Env.NTS_API)}/${resource}`

export const fetchGeoResume = async () => {
  try {
    const fetched = await fetch(NTS_API_URL('forecast/geojson'))

    if (!fetched.ok) {
      throw new Error(status)
    }

    const response = await toJSON(fetched)

    if (!validateGeoResume(response)) {
      throw new Error('Invalid schema.')
    }
    const features = response.features.map(f => ({
      geometry: f.features[0].geometry,
      type: 'Feature',
      properties: {
        DATA: {
          accumulated_precipitation: f.features[0].DATA.precipitation,
          precipitation_accumulated_72h: f.features[0].DATA.precipitation[2],
          slipping: f.features[0].DATA.landslide_index,
          strong_rain: f.features[0].DATA.storm_index,
          wind_speed: f.features[0].DATA.wind_speed,
          lightning: f.features[0].DATA.lightning,
          humidity: f.features[0].DATA.rh,
          color_accumulated_precipitation: f.features[0].DATA.color_precipitation,
          color_precipitation_accumulated_72h: f.features[0].DATA.precipitation[2],
          color_slipping: f.features[0].DATA.color_landslide_index,
          color_strong_rain: f.features[0].DATA.color_storm_index,
          color_wind_speed: f.features[0].DATA.color_wind_speed,
          color_lightning: f.features[0].DATA.color_lightning,
          color_humidity: f.features[0].DATA.color_rh,
        },
        DESCRIPTION: 'Description',
        LAST_UPDATE: {
          date_update_forecast: "2021-06-30T11:29:47", // get from api
          date_update_observed: "2021-06-30T11:29:47"
        },
        NAME: f.features[0].properties.name,
        TYPE: 'SEGMENT',
        TYPE_SEGMENT: f.features[0].properties.name.split('_')[0]
     },
    }));
    const data = {
      type: 'FeatureCollection',
      name: 'Resports Resume',
      features,
    }

    return data
  } catch (error) {
    throw new Error(error)
  }
}

export function fetchResume() {
  return fetch(NTS_API_URL('forecast/geojson')
  )
    .then(toJSON)
    .then(normalizeResume)
}

async function getAllForecastHourly(segments, func) {
  const responses = new Array()

  for (const segment of segments.names){
    const response = await fetch(NTS_API_URL(`forecast/${segment}/hours`)).then(toJSON)

    responses.push(response)

    func(normalizeForecast(normalizeForecastHourly(responses)))
  }

  return responses
}

export function fetchHourly(func) {
  return fetch(NTS_API_URL('segments/all'))
    .then(toJSON)
    .then((segments) => getAllForecastHourly(segments, func))
    .then(normalizeForecastHourly)
    .then(normalizeForecast)
}
