import moment from 'moment'

const putZeroOnLeft = number => {
  if (number < 10) return `0${number}`

  return number
}

export const getSatellite = () => {
  const now15EarlierMinutes = moment()
    .subtract(15, 'minutes')
    .utc()

  const getMinutes = actualMinutes => {
    if (actualMinutes < 15) return '00'
    else if (actualMinutes < 30) return '15'
    else if (actualMinutes < 45) return '30'

    return '45'
  }

  return `https://tiles-somar.somar.io/GOES-16/CMI/C13/${now15EarlierMinutes.year()}/${putZeroOnLeft(now15EarlierMinutes.month() + 1)}/${putZeroOnLeft(now15EarlierMinutes.date())}/${putZeroOnLeft(now15EarlierMinutes.hours())}/${getMinutes(now15EarlierMinutes.minute())}/{z}/{x}/{-y}.png`
}
