import { toJSON } from './utils'
import { Env } from 'tbg/environment'

const URI = `${Env.getEnv(Env.BROLY_URI)}/stations`

const headersConfig = {
  headers: {
    'x-api-key': Env.getEnv(Env.BROLY_KEY)
  }
}

const normalize = data => {
  const { locations, points } = data

  const { station_name, station_id, latitude, longitude } = locations
  const {
    wind_speed,
    air_temperature,
    precipitation_amount,
    relative_humidity
  } = points

  return station_name.map((name, index) => ({
    name,
    id: station_id[index],
    latitude: latitude[index],
    longitude: longitude[index],
    wind: wind_speed && wind_speed[index] ? wind_speed[index] : 0,
    precipitation:
      precipitation_amount && precipitation_amount[index]
        ? precipitation_amount[index]
        : 0,
    temperature:
      air_temperature && air_temperature[index] ? air_temperature[index] : 0,
    humidity:
      relative_humidity && relative_humidity[index]
        ? relative_humidity[index]
        : 0
  }))
}

export function fetchByOrgan(organ) {
  return fetch(`${URI}/${organ}`, headersConfig)
    .then(toJSON)
    .then(normalize)
}
