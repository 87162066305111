import { toJSON } from './utils'
import bounds from '../resources/pipelines/fireMapBounds'

import { Env } from 'tbg/environment'

import moment from 'moment'


const normalize = ({ data })  => {
  let normalized = []

  data.forEach(item => {
    normalized.push({
      latitude: `${item.location.coordinates[1]}`,
      longitude: `${item.location.coordinates[0]}`,
      area: '',
      date: `${item.datetime}`,
      state: '',
      id: `${item._id}`
    })
  })    
  
  return normalized
}

export async function requestLightings() {
  const KONG_URI = resource => `${Env.getEnv(Env.KONG_URI)}/${resource}`

  // const bbox = [
  //   -75.234375,
  //   -33.7243396617476,
  //   -34.453125,
  //   4.565473550710278
  // ]

  // const geometry = turf.bboxPolygon(bbox)

  const payload = {
    geometry: bounds.geometry,
    sources: ['earth networks'],
    page: null,
    perPage: null,
    dateBegin: moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss ZZ'),
    dateEnd: moment().format('YYYY-MM-DD HH:mm:ss ZZ'),
    count: true,
    lightningTypes: ['CG', 'IC'],
  }

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
    body: JSON.stringify(payload)
  }

  return fetch(KONG_URI('history/lightning/geojson'), options)
  .then(toJSON)
  .then(normalize)
  .catch(err => console.log(err))
  
} 