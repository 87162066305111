import React, { Suspense, lazy } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'

import { Menu } from 'tbg/containers'
import { Loading } from 'tbg/components'
import AuthUser from './pages/AuthUser/index';


const getModule = Component => module => ({ default: module[Component] })

const Map = lazy(() => import('tbg/pages').then(getModule('Map')))
const Reports = lazy(() => import('tbg/pages').then(getModule('Reports')))
const Forecasts = lazy(() => import('tbg/pages').then(getModule('Forecasts')))
const Records = lazy(() => import('tbg/pages').then(getModule('Records')))
const Users = lazy(() => import('tbg/pages').then(getModule('Users')))

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Menu>
            <Route path="/" exact component={Map} />
            <Route path="/forecasts" component={Forecasts} />
            <Route path="/records" component={Records} />
            <Route path="/reports" component={Reports} />
            <Route path="/users" component={Users} />
            <Route path="/login" component={AuthUser} />
          </Menu>
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
