import { toJSON } from './utils'
import { Env } from 'tbg/environment'

const URI = Env.getEnv(Env.IMAGERY_URL)

const headersConfig = {
  headers: {
    'x-api-key': Env.getEnv(Env.IMAGERY_KEY)
  },
  cache: 'no-store'
}

const regions = {
  SUDESTE: ['sp', 'rj', 'es', 'mg'],
}

const getRegion = state =>
  Object.keys(regions).find(region =>
    regions[region].includes(state) ? region : null
  )

const addProperties = radars =>
  radars.map(radar => {
    const region = getRegion(radar.state)
    if (region) {
      return {
        ...radar,
        region,
        active: region !== 'NORTE' && region !== 'NORDESTE' ? true : false      
      }
    }
  }).filter(r => r)

export async function fetchRadars() {
  return fetch(`${URI}/radars`, headersConfig)
    .then(toJSON)
    .then(addProperties)
    
}
