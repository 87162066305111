import { sortByName } from '../utils'
import moment from 'moment'

export const normalizeForecast = ({ data, meta }) => {
  const withSort = ({ periods, name, type_segment }) => forecasts => {
   return  sortByName([
      ...forecasts.map((forecast, forecastIndex) =>
        Object.assign(
          {
            name: data.names[forecastIndex],
            type_segment: data.names[forecastIndex].split('_')[0]
          },
          ...forecast.map((value, index) => ({
            [periods[index]]: value
          }))
        )
      )
    ])
  }

  const normalize = withSort(data)
  const normalizedData = {
    data: {
      name: data.names,
      periods: data.periods,
      zone: data.ducts,
      rain: normalize(data.storm_index),
      lightning: normalize(data.lightning),
      humidity: normalize(data.rh),
      slipping: normalize(data.landslide_index),
      wind: normalize(data.wind_speed)
    },
    meta: {
      "date_update": meta.date_update,
      "strong_rain": "mm.period",
      "wind_speed": "km/h",
      "humidity": "%",
      "slipping": null,
      "lightning": "%"
    }
  }
  // get meta from api
  return normalizedData
}

export const normalizeForecastHourly = (data) => {
  const get72hours = (data) => data.slice(0, 72)

  const periods = get72hours(data[0].data.periods)

  const dateUTC = moment(data[0].location.updated_at)
  dateUTC.set('hour', parseInt(dateUTC.format('HH')) + parseInt(data[0].location.time_zone))

  const date_update = dateUTC.format('YYYY-MM-DD HH:MM:SS')

  const normalizedData = {
    periods: periods,
    names: new Array(),
    ducts: new Array(),
    sectors: new Array(),
    tracks: new Array(),
    markers: new Array(),
    temperature: new Array(),
    precipitation: new Array(),
    precipitation_index: new Array(),
    storm_index: new Array(),
    landslide_index: new Array(),
    rh: new Array(),
    lightning: new Array(),
    wind_speed: new Array()
  }

  data.forEach((segment) => {
    const location = segment.location
    const variables = segment.data.variables

    normalizedData.names.push(location.name)
    normalizedData.ducts.push(location.duct)
    normalizedData.sectors.push(location.sector)
    normalizedData.tracks.push(location.track)
    normalizedData.markers.push(location.marker)
    
    normalizedData.temperature.push(get72hours(variables.temperature))
    normalizedData.precipitation.push(get72hours(variables.precipitation))
    normalizedData.precipitation_index.push(get72hours(variables.precipitation_index))
    normalizedData.storm_index.push(get72hours(variables.storm_index))
    normalizedData.landslide_index.push(get72hours(variables.landslide_index))
    normalizedData.rh.push(get72hours(variables.rh))
    normalizedData.lightning.push(get72hours(variables.lightning))
    normalizedData.wind_speed.push(get72hours(variables.wind_speed))
  })

  return {
    data: normalizedData,
    meta: {
      date_update
    }
  }
}

export const normalizeResume = (data) => {
  const segments = data.features
  const last_update = segments[0].features[0].LAST_UPDATE.date_update_forecast || ""
  const normalizeData = {}

  segments.forEach((segment) => {

    const dataSegment = segment.features[0]
    const segmentName = dataSegment.properties.name
    const forecast = dataSegment.DATA

    normalizeData[segmentName] = {
      name: segmentName,
      type_segment: segmentName.split('_')[0],
      precipitation: forecast.precipitation[0],
      lastLightnings: {
        twentyFourHours: 0,
        thirtyDays: 0
      },
      storm_index: {
        '24h': forecast.storm_index[0],
        '48h': forecast.storm_index[1],
        '72h': forecast.storm_index[2]
      },
      wind: {
        '24h': forecast.wind_speed[0],
        '48h': forecast.wind_speed[1],
        '72h': forecast.wind_speed[2]
      },
      humidity: {
        '24h': forecast.rh[0],
        '48h': forecast.rh[1],
        '72h': forecast.rh[2]
      },
      slipping: {
        '24h': forecast.landslide_index[0],
        '48h': forecast.landslide_index[1],
        '72h': forecast.landslide_index[2]
      },
      lightning: {
        '24h': forecast.lightning[0],
        '48h': forecast.lightning[1],
        '72h': forecast.lightning[2]
      }
    }
  })

  const sortedNormalizedData = []

  Object.keys(normalizeData).sort().forEach((segment) => {
    sortedNormalizedData.push(normalizeData[segment])
  })

  return {
    meta: {
      steps: ['24h', '48h', '72h'],
      units: {
        date_update_forecast: last_update
      }
    },
    data: sortedNormalizedData
  }
}
