import { slice, groupBy } from 'ramda'

export const slicer = slice(0, 15)

export const toFixed = value => parseInt(parseFloat(value).toFixed(2))

export const groupByDayAndMonth = structured =>
  groupBy(({ date }) => date.format('YYYY-MM-DD'), structured)

export const degToCompass = deg => {
  const mapping = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW'
  ]

  const val = Math.floor(deg / 22.5 + 0.5)
  return mapping[val % 16]
}

export const toInitial = (value, hour) => {
  const indifferents = {
    '3': 'EN',
    '7': 'CH'
  }

  const initials = {
    '0': 'CC',
    '1': 'PN',
    '2': 'NB',
    '4': 'PI',
    '5': 'NC',
    '6': 'PC'
  }

  const periods = {
    '00': 'night',
    '06': 'day',
    '12': 'day',
    '18': 'night'
  }

  return indifferents[value]
    ? indifferents[value].toLowerCase()
    : `${periods[hour]}${initials[value]}`
}

export const toPeriod = hour => {
  const periods = {
    '00': 'Madrugada',
    '06': 'Manhã',
    '12': 'Tarde',
    '18': 'Noite'
  }

  return periods[hour]
}

export const toDescription = value => {
  const mapping = {
    '0': 'Céu claro',
    '1': 'Poucas nuvens',
    '2': 'Nublado',
    '3': 'Encoberto',
    '4': 'Pancadas isoladas',
    '5': 'Nublado com chuva',
    '6': 'Pancadas de chuva com trovoadas',
    '7': 'Chuvoso'
  }

  return mapping[value]
}

export function groupByPeriod(period, segment) {
  const periodFormated = [];

  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const average = (values) => values.reduce(reducer)/period;
  const maxIndex = (x,y) => Math.max(x,y);

  for( var i=0; i<segment.data.periods.length; i+=period){
      const date = segment.data.periods[i];

      const rain = segment.data.variables.precipitation.slice(i,i+period).reduce(reducer);

      const humidity = average(segment.data.variables.rh.slice(i,i+period))
      const temperature = average(segment.data.variables.temperature.slice(i,i+period))
      const windSpeed = average(segment.data.variables.temperature.slice(i,i+period))
      const lightning = average(segment.data.variables.lightning.slice(i,i+period))

      const stormIndex = segment.data.variables.storm_index.slice(i, i+period).reduce(maxIndex);
      const landslideIndex = segment.data.variables.landslide_index.slice(i, i+period).reduce(maxIndex);

      periodFormated.push({date, rain, humidity, temperature, windSpeed, stormIndex, landslideIndex, lightning});
  }

  return periodFormated;
}