import React, { useContext, useState } from 'react'
import styled, { ThemeContext, css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import posed from 'react-pose'
import PropTypes from 'prop-types'

import { Navigable } from 'tbg/containers'
import { Footer } from 'tbg/components'

import { Text } from '@somarmeteorologia/momentum'

import logo from 'tbg/resources/logo.svg'

const Container = styled.div`
  height: 100vh;
  z-index: ${theme('zindex.drawer')};
  box-shadow: ${theme('navigation.shadow.primary')};
`

const Organization = posed(styled.div`
  background-color: ${theme('navigation.bg.primary')};
  height: 70px;
  display: flex;
  align-items: center;

  ${ifProp(
    { toggled: false },
    css`
      padding: 0 15px;
    `
  )}
`)({
  visible: {
    width: 270
  },
  collapsed: {
    width: 10
  }
})

const Logo = styled.div`
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 6px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme('bg.primary')};
  box-shadow: 0px 2px 3px ${theme('shadow.default')};

  img {
    max-width: 20px;
  }
`

export function Sidebar({ toOpenToast }) {
  const [toggled, setToggled] = useState(false)
  const { animations, text } = useContext(ThemeContext)

  return (
    <Container>
      <Organization
        toggled={toggled}
        initialPose={animations.visible}
        pose={toggled ? animations.collapsed : animations.visible}
      >
        {!toggled && (
          <>
            <Logo>
              <img src={logo} alt="TBG" />
            </Logo>
            <Text
              data-testid="title"
              color={text.primary}
              size={Text.size.twelve}
              weight={Text.weight.bold}
            >
              NTS - PORTAL METEOROLÓGICO
            </Text>
          </>
        )}
      </Organization>

      <Navigable toOpenToast={toOpenToast} setToggled={setToggled} />

      <Footer
        toggled={toggled}
        initialPose={animations.visible}
        pose={toggled ? animations.collapsed : animations.visible}
      />
    </Container>
  )
}

Sidebar.propTypes = {
  toOpenToast: PropTypes.func.isRequired
}
