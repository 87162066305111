import { toJSON } from './utils'
import { Env } from 'tbg/environment'
import moment from 'moment'

const NTS_AWS_URL = resource => `${Env.getEnv(Env.NTS_AWS)}/${resource}`

const normalizeReports = data => {
  const types = {
    pdf: {
      daily: {
        title: 'Resumo da previsão do tempo - Chuva/Deslizamento'
      },
      weekly: {
        title: 'Boletim Semanal da previsão do tempo'
      },
      monthly: {
        title: 'Boletim Mensal da previsão do tempo'
      }
    },
    xls: {
      daily: {
        title: 'Planilha com dados meteorologicos'
      }
    }
  }

  const bulletins = data.map((item) => {
    item.title = types[item.extension][item.type].title

    return item
  })

  return bulletins.sort((a,b) => new Date(b.date) - new Date(a.date))
}

async function getReports() {
  const awsFiles = await fetch(
    NTS_AWS_URL('nts.json'),
    {
      cache: 'no-store'
    }
  ).then(toJSON)

  const bulletins = {
    daily: [],
    weekly: [],
    monthly: []
  }

  awsFiles.Contents.forEach((item) => {
    const name = item.Key.split("_")

    if(item.Key.toLowerCase().includes("atual")){
      return
    }

    if (name[0] === "nts") {
      const type = name[1]

      let date = name[3].split(".")[0]
      date = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")

      const extension = (item.Key.indexOf('.pdf') !== -1) ? 'pdf' : 'xls'

      bulletins[type].push({
        date,
        extension,
        type,
        title: item.Key,
        url: NTS_AWS_URL(item.Key)
      })
    }
  })

  return [
    ...bulletins.daily,
    ...bulletins.weekly,
    ...bulletins.monthly
  ]
}

export function fetchReports() {
  return getReports()
    .then(normalizeReports)
}