import { toJSON } from '../utils'
import { groupByPeriod } from './utils'
import { Env } from 'tbg/environment'

import { validateFifteenDaysWithPeriods, validateFifteenDays } from './validate'
import {
  normalizePeriodly,
  normalizeMonthlyRecords,
  normalizeSegmentDaily,
  normalizeDaily,
  normalizeSegmentPeriodly
} from './normalize'

const NIMBUS_URL = resource => `${Env.getEnv(Env.NIMBUS_URI)}/${resource}`
const NTS_API_URL = resource => `${Env.getEnv(Env.NTS_API)}/${resource}`

const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': Env.getEnv(Env.NIMBUS_KEY)
  }
}

export function fetchPeriodly(locality) {
  if (locality.category === 'SEGMENT') {
    return fetch(
      NTS_API_URL(
        `forecast/${locality.value}/hours`)
    )
      .then(toJSON)
      .then((data) => groupByPeriod(6, data))
      .then(normalizeSegmentPeriodly)
  }

  const [latitude, longitude] = locality.value;

  return fetch(
    NIMBUS_URL(`forecast/15days?latitude=${latitude}&longitude=${longitude}&periods=true`),
    defaultConfig
  )
    .then(toJSON)
    .then(validateFifteenDaysWithPeriods)
    .then(normalizePeriodly)
}

export function fetchDaily(locality) {
  if (locality.category === 'SEGMENT') {
    return fetch(
      NTS_API_URL(`forecast15/${locality.value}`)
    )
      .then(toJSON)
      .then(normalizeSegmentDaily)
  }

  const [latitude, longitude] = locality.value;

  return fetch(
    NIMBUS_URL(`forecast/15days?latitude=${latitude}&longitude=${longitude}`),
    defaultConfig
  )
    .then(toJSON)
    .then(validateFifteenDays)
    .then(normalizeDaily)
}

export function fetchMonthlyRecords({ latitude, longitude }) {
  return fetch(
    NIMBUS_URL(`observed/monthly?latitude=${latitude}&longitude=${longitude}&current=true`
    ),
    defaultConfig
  )
    .then(toJSON)
    .then(normalizeMonthlyRecords)
}